import React, { FunctionComponent } from 'react';
import { Form, Message } from 'semantic-ui-react';

interface Props {
  errors: string[];
}

const FormError: FunctionComponent<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { errors } = props;
  return (
    <Form.Field>
      {errors.map((error) => (
        <Message key={error} negative>
          <p>{error}</p>
        </Message>
      ))}
    </Form.Field>
  );
};

export default FormError;
