import { FormikErrors } from 'formik';
import { camelCase, mapKeys } from 'lodash';

const standardRelayError =
  (
    setLoading: (loading: boolean) => void,
    setErrors: ((errors: FormikErrors<unknown>) => void) | null,
    setWholeFormErrors: (errors: string[]) => void
  ) =>
  (error: Error): void => {
    setLoading(false);
    if (error.name === 'AjaxError') {
      setWholeFormErrors(['Unable to connect to server']);
    } else {
      if (error.message.startsWith('{')) {
        // Message is JSON, a Django errors dict
        const errors = JSON.parse(error.message);
        if (setErrors) {
          setErrors(mapKeys(errors, (v, k) => camelCase(k)));
        } else {
          setWholeFormErrors(errors);
        }
      } else {
        setWholeFormErrors([error.message]);
      }
    }
  };

export default standardRelayError;
