import { useEffect, useMemo, useState } from 'react';

import {
  MobileSession,
  Session,
  getOrRefreshToken,
  isTokenExpired,
} from './session.utils';

type AuthStateType = 'unsure' | 'authenticated' | 'not-authenticated';

const useAuthState = (
  session: Session | MobileSession | undefined
): AuthStateType => {
  const [authState, setAuthState] = useState<AuthStateType>('unsure');

  const simplifiedAuthState: AuthStateType | 'pending' = useMemo(() => {
    if (session && session.token) {
      if (!isTokenExpired(session.token)) {
        return 'authenticated';
      } else {
        return 'pending';
      }
    } else {
      return 'not-authenticated';
    }
  }, [session]);

  useEffect(() => {
    if (simplifiedAuthState === 'pending') {
      getOrRefreshToken()
        .then(() => {
          setAuthState('authenticated');
        })
        .catch((err) => {
          console.error(err);
          setAuthState('not-authenticated');
        });
    }
  }, [simplifiedAuthState]);

  if (simplifiedAuthState === 'pending') {
    return authState;
  } else {
    return simplifiedAuthState;
  }
};

export default useAuthState;
