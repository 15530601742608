import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-relay';
import { Button, Icon } from 'semantic-ui-react';
import * as Yup from 'yup';

import FORGOT_PASSWORD, {
  AuthForgotPasswordMutation,
} from '@relate/relay/common-queries/__generated__/AuthForgotPasswordMutation.graphql';

import styles from '../../pages/index.module.css';

import LoadingIndicator from './LoadingIndicator.component';
import FormError from './form-error';
import standardRelayError from './standard-relay-error';

interface Props {
  goToLogin: () => void;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('*Invalid e-mail').required('*Can not be empty'),
});

const ResetPasswordRequestForm = (props: Props) => {
  const { goToLogin } = props;

  const [notifMsg, setNotifMsg] = useState('');

  const [forgotPassword] =
    useMutation<AuthForgotPasswordMutation>(FORGOT_PASSWORD);
  const [wholeFormErrors, setWholeFormErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <LoadingIndicator loading={loading} msg="Loading..." />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors }) => {
          setLoading(true);
          forgotPassword({
            variables: {
              input: values?.email,
            },
            onCompleted: () => {
              setLoading(false);
              setNotifMsg(
                "We've sent you the email, please check your inbox or spam folder"
              );
            },
            onError: standardRelayError(
              setLoading,
              setErrors,
              setWholeFormErrors
            ),
          });
        }}
      >
        {(formikProps) => {
          const { errors } = formikProps;
          return (
            <Form className={styles.loginForm}>
              <div className={styles.formInput}>
                <div onClick={goToLogin}>
                  <Icon name="arrow left" />
                </div>
                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                  Forgotten your password? Enter your email address below and
                  we&apos;ll email instructions for setting a new one
                </div>
                <label htmlFor="email">E-mail</label>
                <Field
                  id="email"
                  name="email"
                  placeholder="admin@gmail.com"
                  type="email"
                />
              </div>
              {errors.email}
              <div className={styles.centered}>
                <Button primary type="submit">
                  Send Request
                </Button>
              </div>
              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                {notifMsg}
              </div>
            </Form>
          );
        }}
      </Formik>
      {!!wholeFormErrors.length && <FormError errors={wholeFormErrors} />}
    </>
  );
};

export default ResetPasswordRequestForm;
