import React, { useState } from 'react';
import { Header, Image } from 'semantic-ui-react';

import styles from '../../pages/index.module.css';

import LoginForm from './LoginForm';
import ResetPasswordRequestForm from './ResetPasswordRequestForm';

const LoginPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const renderLoginForm = (
    <>
      <Header textAlign="center" className={styles.loginPageHeader}>
        Admin Log in
      </Header>
      <LoginForm goToResetPassword={() => setIsLoggedIn(false)} />
    </>
  );
  const renderResetPasswordForm = (
    <>
      <Header textAlign="center" className={styles.loginPageHeader}>
        Request Password Reset
      </Header>
      <ResetPasswordRequestForm goToLogin={() => setIsLoggedIn(true)} />
    </>
  );

  const renderForm = isLoggedIn ? renderLoginForm : renderResetPasswordForm;
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <Image src="/ColorFull_Logo.png" alt="" size="small" centered />
        {renderForm}
      </div>
    </div>
  );
};

export default LoginPage;
