/**
 * @generated SignedSource<<157617d282fee5fba43a951b598d64de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthForgotPasswordMutation$variables = {
  input: string;
};
export type AuthForgotPasswordMutation$data = {
  readonly forgotPassword: {
    readonly email: string | null;
  } | null;
};
export type AuthForgotPasswordMutation = {
  variables: AuthForgotPasswordMutation$variables;
  response: AuthForgotPasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "input"
      }
    ],
    "concreteType": "UserNode",
    "kind": "LinkedField",
    "name": "forgotPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3f9d4c75e18413c093fadad4c17f5e42",
    "id": null,
    "metadata": {},
    "name": "AuthForgotPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation AuthForgotPasswordMutation(\n  $input: String!\n) {\n  forgotPassword(email: $input) {\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "4cadbb4c5be5596d9a498ac7f1c4c5ef";

export default node;
