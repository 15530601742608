import { useRouter } from 'next/router';
import * as React from 'react';

import { loadSession } from '@relate/relay/session.utils';
import useAuthState from '@relate/relay/useAuthState.hook';

import LoadingIndicator from '../components/auth/LoadingIndicator.component';
import LoginPage from '../components/auth/LoginPage.component';

export function Index() {
  const session = loadSession();
  const authState = useAuthState(session);
  const router = useRouter();

  if (authState === 'authenticated') {
    router.push('/dashboard');
    return <></>;
  }
  if (authState === 'unsure') {
    return <LoadingIndicator loading msg="Loading..." />;
  }
  if (authState === 'not-authenticated') {
    return <LoginPage />;
  }
  return null;
}

export default Index;
