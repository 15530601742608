import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/dist/client/router';
import React, { useState } from 'react';
import { useMutation } from 'react-relay';
import { Button, Radio } from 'semantic-ui-react';
import * as Yup from 'yup';
import 'yup-phone';

import TOKEN_AUTH, {
  AuthTokenAuthMutation,
} from '@relate/relay/common-queries/__generated__/AuthTokenAuthMutation.graphql';

import styles from '../../pages/index.module.css';

import LoadingIndicator from './LoadingIndicator.component';
import FormError from './form-error';
import standardRelayError from './standard-relay-error';

interface Props {
  goToResetPassword: () => void;
}
const validationSchema = Yup.object().shape({
  email: Yup.string().email('*Invalid e-mail').required('*Cannot be empty'),
  password: Yup.string()
    .trim()
    .required('*Cannot be empty')
    .min(6, '*Minimum 6 Characters'),
});

const LoginForm = (props: Props) => {
  const { goToResetPassword } = props;
  const [checked, setChecked] = useState(false);

  const [signIn] = useMutation<AuthTokenAuthMutation>(TOKEN_AUTH);
  const [wholeFormErrors, setWholeFormErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  return (
    <>
      <LoadingIndicator loading={loading} msg="Signing in" />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors }) => {
          setLoading(true);
          setWholeFormErrors([]);
          const onError = standardRelayError(
            setLoading,
            setErrors,
            setWholeFormErrors
          );
          signIn({
            variables: {
              input: {
                email: values.email,
                password: values.password,
              },
            },
            onCompleted: (response) => {
              console.log('Logged in : ', response);
              router.push('/');
              setLoading(false);
            },
            onError,
          });
        }}
      >
        {(formikProps) => {
          const { errors } = formikProps;
          return (
            <Form className={styles.loginForm}>
              <div className={styles.formInput}>
                <label htmlFor="email">E-mail</label>
                <Field
                  id="email"
                  name="email"
                  placeholder="admin@gmail.com"
                  type="email"
                />
                {errors.email}
              </div>
              <div className={styles.formInput}>
                <label htmlFor="password">Password</label>
                <Field id="password" name="password" type="password" />
                {errors.password}
                <div
                  className={styles.forgotPassword}
                  onClick={goToResetPassword}
                >
                  Forgot Password
                </div>
              </div>
              <div className={styles.rememberMe}>
                <Radio
                  label="Remember Me"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
              </div>
              <div className={styles.centered}>
                <Button primary type="submit">
                  Log in
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      {!!wholeFormErrors.length && <FormError errors={wholeFormErrors} />}
    </>
  );
};

export default LoginForm;
